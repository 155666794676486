import React from 'react';
import { LatLngExpression } from 'leaflet';
import { ReactElement } from 'react';
import {
  LayersControl,
  MapContainer,
  MapContainerProps,
  TileLayer,
} from 'react-leaflet';

type MapProps = {
  center?: LatLngExpression;
  zoom?: number;
  scrollWheelZoom?: boolean;
  baseLayers?: Array<ReactElement>;
  overlays?: Array<ReactElement>;
  controls?: Array<ReactElement>;
  mapProps?: Omit<
    MapContainerProps,
    'children' | 'center' | 'zoom' | 'scrollWheelZoom'
  >;
};

export default function CustomMap(props: MapProps) {
  const {
    center = [-14.2400732, -53.1805017],
    zoom = 16,
    scrollWheelZoom = false,
    mapProps,
    overlays,
    controls,
  }: MapProps = props;

  return (
    <MapContainer
      center={center}
      zoom={zoom}
      scrollWheelZoom={scrollWheelZoom}
      {...mapProps}
      attributionControl={false}
    >
      <LayersControl hideSingleBase>
        <LayersControl.BaseLayer checked name="Mapa">
          <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
        </LayersControl.BaseLayer>
        {overlays &&
          overlays.map((layer, index) => (
            <React.Fragment key={index}>{layer}</React.Fragment>
          ))}
      </LayersControl>
      {controls &&
        controls.map((control, index) => (
          <React.Fragment key={index}>{control}</React.Fragment>
        ))}
    </MapContainer>
  );
}
