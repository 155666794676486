import React, { useState, useRef, useEffect } from 'react';
import Button from '../../../../components/Button/Button.component';
import PasswordInput from '../../../../components/PasswordInput/PasswordInput.component';
import TextInput from '../../../../components/TextInput/TextInput.component';
import styles from './Account.module.css';
import Icon from '../../../../components/Icon/Icon.component';
import useStateSelector from '../../../../store/hooks/useStateSelector.hook';
import { AccountMSTypes } from '../../../../types/definitions';
import { AuthSliceType } from '../../../../store/slices/auth/auth.slice';
import AccountMS from '../../../../services/ms/AccountMS.service';
import { Modal } from '../../components/Modal';
import { useDispatch } from 'react-redux';
import { authSliceActions } from '../../../../store/slices/auth/auth.slice';

export default function Account() {
  const dispatch = useDispatch();
  const { value: auth }: AuthSliceType = useStateSelector('auth');

  const [data, setData] = useState<AccountMSTypes.ById | undefined>(undefined);

  const [image, setImage] = useState<string | undefined>(
    data?.photo || undefined
  );
  const [password, setPassword] = useState<[string, string]>(['', '']);
  const [modalContent, setModalContent] = useState<string>(
    'Houve um erro ao atualizar os dados!'
  );

  const fileInputRef = useRef<HTMLInputElement>(null);
  const modal = useRef<HTMLDialogElement>(null);
  const customContentModal = useRef<HTMLDialogElement>(null);
  const modalErrorPassword = useRef<HTMLDialogElement>(null);
  const modalFillFields = useRef<HTMLDialogElement>(null);

  const [toUpdateData, setToUpdateData] = useState<{
    name: string;
    email: string;
    photo: any;
  }>({
    name: '',
    email: '',
    photo: undefined,
  });

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setToUpdateData((prev) => ({ ...prev, photo: file }));
    if (file && file.type.startsWith('image/')) {
      setImage(URL.createObjectURL(file));
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files[0] && files[0].type.startsWith('image/')) {
      setToUpdateData((prev) => ({ ...prev, photo: files[0] }));
      setImage(URL.createObjectURL(files[0]));
    }
  };

  const handleBoxClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  function validarSenha(senha) {
    const padrao =
      /^(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{6,})[A-Za-z0-9]*[^A-Za-z0-9][A-Za-z0-9]*$/;
    return padrao.test(senha);
  }

  async function onSave() {
    const form_data = new FormData();

    form_data.append('name', toUpdateData.name);
    form_data.append('email', toUpdateData.email);

    if (toUpdateData.photo instanceof File) {
      form_data.append('photo', toUpdateData.photo);
    }

    if ((password[0] && !password[1]) || (!password[0] && password[1])) {
      if (modalFillFields.current) {
        modalFillFields.current.showModal();
      }
      return;
    }

    if (password[0] || password[1]) {
      if (validarSenha(password[0]) && password[0] === password[1]) {
        form_data.append('password', password[0]);
      } else {
        if (modalErrorPassword.current) {
          modalErrorPassword.current.showModal();
        }
        return;
      }
    }

    if (!auth.user) return;

    try {
      const response = await AccountMS.Update(auth.user.id, form_data);

      if (!response) {
        customContentModal.current?.showModal();
        return;
      }

      setPassword(['', '']);

      if ('id' in response) {
        dispatch(
          authSliceActions.update({
            name: toUpdateData.name,
            email: toUpdateData.email,
            photo:
              toUpdateData.photo instanceof File
                ? undefined
                : toUpdateData.photo,
          })
        );

        modal.current?.showModal();
        return;
      }

      setModalContent(
        response.message || 'Houve um erro ao atualizar os dados!'
      );
      customContentModal.current?.showModal();
    } catch (error) {
      setModalContent('Houve um erro ao atualizar os dados!');
      customContentModal.current?.showModal();
    }
  }

  useEffect(() => {
    async function fetchData() {
      if (!auth.user) return;

      const responses = await Promise.allSettled([
        AccountMS.ById(auth.user.id),
      ]);

      responses.forEach((response, index) => {
        if (response.status !== 'fulfilled') {
          return;
        }
        if (response.value === false) {
          return;
        }

        if (index === 0) {
          const fetchedData = response.value as AccountMSTypes.ById;
          setData(fetchedData);

          setToUpdateData({
            name: fetchedData.name || '',
            email: fetchedData.email || '',
            photo: fetchedData.photo || '',
          });
        }
      });
    }

    fetchData();

    return () => {
      window.history.replaceState({}, '');
    };
  }, [auth.user]);

  return (
    <div className={styles.container}>
      <div className={styles.divForm}>
        <TextInput
          label="Nome"
          className={styles.textInput}
          value={toUpdateData.name}
          onChange={(e) =>
            setToUpdateData((prev) => ({ ...prev, name: e.target.value }))
          }
          inputProps={{
            defaultValue: data?.name,
          }}
        />
        <TextInput
          label="Email"
          className={styles.textInput}
          value={toUpdateData.email}
          onChange={(e) =>
            setToUpdateData((prev) => ({ ...prev, email: e.target.value }))
          }
          inputProps={{
            defaultValue: data?.email,
          }}
        />
        <div className={styles.divPassword}>
          <PasswordInput
            label="Senha"
            value={password[0]}
            onChange={(e) => setPassword((prev) => [e.target.value, prev[1]])}
            className={styles.passwordInput}
            iconPassword={true}
          />
          <PasswordInput
            label="Repetir Senha"
            value={password[1]}
            onChange={(e) => setPassword((prev) => [prev[0], e.target.value])}
            className={styles.passwordInput}
            iconPassword={true}
          />
        </div>
        <div className={styles.divButton}>
          <Button
            label="Salvar"
            onClick={onSave}
            className={styles.buttonSave}
          />
        </div>
      </div>
      <div className={styles.divUpload}>
        <div
          className={styles.uploadBox}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onClick={handleBoxClick}
          data-testid="upload-box"
        >
          {!data?.photo && !image && (
            <>
              <p>Foto de perfil</p>
              <div className={styles.dragText}>
                <Icon name="ClosedHand" />
                <p>
                  Arraste a imagem para esta área ou clique para fazer o upload:
                </p>
              </div>
              <Button
                label="Selecionar Arquivo"
                className={styles.buttonUpload}
              />
            </>
          )}

          {data?.photo && !image && (
            <img
              src={data.photo}
              alt="Foto de perfil"
              className={styles.image}
            />
          )}
          {image && (
            <img src={image} alt="Foto de perfil" className={styles.image} />
          )}
        </div>
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          className={styles.fileInput}
          data-testid="file-input"
        />
      </div>
      <Modal.ActionResponse
        forwardRef={modal}
        title="Dados atualizados com sucesso!"
        // onClosed={() => window.location.reload()}
      />
      <Modal.ActionResponse
        forwardRef={customContentModal}
        title={modalContent}
        // onClosed={() => window.location.reload()}
      />
      <Modal.ActionResponse
        forwardRef={modalErrorPassword}
        title="Senha inválida ou diferente!"
      />
      <Modal.ActionResponse
        forwardRef={modalFillFields}
        title="Preencha todos os campos"
      />
    </div>
  );
}
