import { AccountMSTypes } from '../../types/definitions';
import HTTP from '../HTTP';

export default abstract class AccountMS {
  private static readonly ms_base_url: string = `${process.env
    .REACT_APP_ACCOUNT_MS!}/api/accounts`;

  public static async All() {
    return await HTTP.GET(`${this.ms_base_url}`);
  }

  public static async ById(id: string) {
    return await HTTP.GET<AccountMSTypes.ById>(`${this.ms_base_url}/${id}`);
  }

  public static async Invite(
    id: string,
    body: { profile_id: string; emails: string[] }
  ) {
    return await HTTP.POST(`${this.ms_base_url}/${id}/invite`, body);
  }

  public static async Update(id: string, body: FormData) {
    return await HTTP.PATCH<
      any,
      { id: string } | { error: string; message: string; statusCode: number }
    >(
      `${this.ms_base_url}/${id}`,
      body,
      {
        Accept: 'application/json',
      },
      'form'
    );
  }

  public static async Delete(id: string, profileId: string) {
    return await HTTP.DELETE(`${this.ms_base_url}/${id}/profile/${profileId}`);
  }
}
