import React, { MouseEvent, useRef, useState } from 'react';
import styles from './EditUserModal.module.css';
import iconClose from '../../../../../assets/close.svg';
import { AccountMSTypes } from '@spot-spotsat/types/definitions';
import TextInput from '@spot-spotsat/components/TextInput/TextInput.component';
import Button from '@spot-spotsat/components/Button/Button.component';
import PasswordInput from '@spot-spotsat/components/PasswordInput/PasswordInput.component';
import Icon from '@spot-spotsat/components/Icon/Icon.component';
import AccountMS from '@spot-spotsat/services/ms/AccountMS.service';
import { useDispatch } from 'react-redux';
import { authSliceActions } from '@spot-spotsat/store/slices/auth/auth.slice';

interface EditUserProps {
  forwardRef?: React.RefObject<HTMLDialogElement>;
  user?: AccountMSTypes.ById;
  onClose?: (success?: boolean) => void;
}

export default function EditUserModal(props: EditUserProps) {
  const { forwardRef, user, onClose }: EditUserProps = props;

  const dispatch = useDispatch();

  const [validations, setValidations] = useState<{
    isValidName: boolean;
    isValidEmail: boolean;
    isValidPasswordSecurity: boolean;
    doPasswordMatch: boolean;
    didRequestFail: boolean;
  }>({
    isValidName: true,
    isValidEmail: true,
    isValidPasswordSecurity: true,
    doPasswordMatch: true,
    didRequestFail: false,
  });

  const [toUpdateData, setToUpdateData] = useState<{
    name?: string;
    email?: string;
    photo?: any;
  }>({ name: user?.name, email: user?.email, photo: user?.photo });

  const [password, setPassword] = useState<[string, string]>(['', '']);

  const [image, setImage] = useState<string | undefined>(
    user?.photo || undefined
  );

  const fileInputRef = useRef<HTMLInputElement>(null);

  function validarSenha(senha) {
    const padrao =
      /^(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{6,})[A-Za-z0-9]*[^A-Za-z0-9][A-Za-z0-9]*$/;
    return padrao.test(senha);
  }

  async function onSave(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    const form_data = new FormData();

    toUpdateData.name && form_data.append('name', toUpdateData.name);
    toUpdateData.email && form_data.append('email', toUpdateData.email);

    if (toUpdateData.photo instanceof File) {
      form_data.append('photo', toUpdateData.photo);
    }

    if ((password[0] && !password[1]) || (!password[0] && password[1])) {
      setValidations((prev) => ({ ...prev, doPasswordMatch: false }));
      return;
    }

    if (password[0] || password[1]) {
      if (validarSenha(password[0]) && password[0] === password[1]) {
        form_data.append('password', password[0]);
      } else {
        setValidations((prev) => ({ ...prev, isValidPasswordSecurity: false }));
        return;
      }
    }

    if (
      !validations.isValidName ||
      !validations.isValidEmail ||
      !validations.isValidPasswordSecurity ||
      !validations.doPasswordMatch ||
      validations.didRequestFail
    ) {
      return;
    }

    try {
      if (!user) return;

      const response = await AccountMS.Update(user.id, form_data);

      if (!response) {
        return;
      }

      setPassword(['', '']);

      if ('id' in response) {
        dispatch(
          authSliceActions.update({
            name: toUpdateData.name,
            email: toUpdateData.email,
            photo:
              toUpdateData.photo instanceof File
                ? undefined
                : toUpdateData.photo,
          })
        );

        setValidations((prev) => ({ ...prev, didRequestFail: false }));
        onCloseWrapper(true);
        return;
      }

      setValidations((prev) => ({ ...prev, didRequestFail: true }));
      return;
    } catch (error) {
      setValidations((prev) => ({ ...prev, didRequestFail: true }));
      return;
    }
  }

  function onCloseWrapper(success?: boolean) {
    onClose && onClose(success);
    forwardRef?.current?.close();
    setImage(undefined);
    setToUpdateData({ name: '', email: '', photo: undefined });
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }

  function handleDrop(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setToUpdateData((prev) => ({ ...prev, photo: file }));
    if (file && file.type.startsWith('image/')) {
      setImage(URL.createObjectURL(file));
    }
  }

  function handleDragOver(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
  }

  function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const files = e.target.files;
    if (files && files[0] && files[0].type.startsWith('image/')) {
      setToUpdateData((prev) => ({ ...prev, photo: files[0] }));
      setImage(URL.createObjectURL(files[0]));
    }
  }

  function handleBoxClick() {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }

  return (
    <dialog ref={forwardRef}>
      <div className={styles.card}>
        <div className={styles.divIconClose}>
          <h4 className={styles.iconTitle}>Editar usuário</h4>
          <img
            src={iconClose}
            alt="close"
            className={styles.iconClose}
            onClick={() => onCloseWrapper()}
          />
        </div>
        {validations.didRequestFail && (
          <div className={styles.divIconClose}>
            <p className={styles.error}>
              Houve um erro ao tentar salvar os dados. Por favor tente novamente
              mais tarde.
            </p>
          </div>
        )}
        <div className={styles.cardBody}>
          <div className={styles.cardBodyInputs}>
            <div className={styles.fieldContainer}>
              <TextInput
                label="Nome"
                className={styles.textInput}
                value={toUpdateData.name}
                onChange={(e) =>
                  setToUpdateData((prev) => ({ ...prev, name: e.target.value }))
                }
                inputProps={{ defaultValue: user?.name }}
              />
              {!validations.isValidName && (
                <p className={styles.error}>Insira um nome válido!</p>
              )}
            </div>
            <div className={styles.fieldContainer}>
              <TextInput
                label="Email"
                className={styles.textInput}
                value={toUpdateData.email}
                onChange={(e) =>
                  setToUpdateData((prev) => ({
                    ...prev,
                    email: e.target.value,
                  }))
                }
                inputProps={{ defaultValue: user?.email }}
              />
              {!validations.isValidEmail && (
                <p className={styles.error}>Insira um email válido!</p>
              )}
            </div>
            <div className={styles.fieldContainer}>
              <div className={styles.divPassword}>
                <PasswordInput
                  label="Senha"
                  value={password[0]}
                  onChange={(e) =>
                    setPassword((prev) => [e.target.value, prev[1]])
                  }
                  className={styles.passwordInput}
                  iconPassword={true}
                />
                <PasswordInput
                  label="Repetir Senha"
                  value={password[1]}
                  onChange={(e) =>
                    setPassword((prev) => [prev[0], e.target.value])
                  }
                  className={styles.passwordInput}
                  iconPassword={true}
                />
              </div>
              {!validations.isValidPasswordSecurity && (
                <p className={styles.error}>Insira uma senha válida!</p>
              )}
              {!validations.doPasswordMatch && (
                <p className={styles.error}>As senhas precisam ser iguais!</p>
              )}
            </div>
          </div>
          <div className={styles.divUpload}>
            <div
              className={styles.uploadBox}
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onClick={handleBoxClick}
              data-testid="upload-box"
            >
              {!user?.photo && !image && (
                <>
                  <p>Foto de perfil</p>
                  <div className={styles.dragText}>
                    <Icon name="ClosedHand" />
                    <p>
                      Arraste a imagem para esta área ou clique para fazer o
                      upload:{' '}
                    </p>
                  </div>
                  <Button
                    label="Selecionar Arquivo"
                    className={styles.buttonUpload}
                  />
                </>
              )}

              {user?.photo && !image && (
                <img
                  src={user?.photo}
                  alt="Foto de perfil"
                  className={styles.image}
                />
              )}
              {image && (
                <img
                  src={image}
                  alt="Foto de perfil"
                  className={styles.image}
                />
              )}
            </div>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className={styles.fileInput}
              data-testid="file-input"
            />
          </div>
        </div>
        <div className={styles.divButton}>
          <Button
            label="Salvar"
            onClick={(e) => onSave(e)}
            className={styles.buttonSave}
          />
        </div>
      </div>
    </dialog>
  );
}
