import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import styles from './ComplianceAmbiental.module.css';
import { OperationMSTypes } from '../../../../../types/definitions';
import groupBy from '../../../../../utils/groupBy.util';
import CustomMap from '../../../../../components/Map/Map.component';
import ComplianceOverlay from '../../../../../components/Map/overlays/ComplianceOverlay.overlay';
import toFeatureCollection from '../../../../../utils/geo/toFeatureCollection.util';
import getCentroid from '../../../../../utils/geo/getCentroid.util';
import getBbox from '../../../../../utils/geo/getBbox.util';
import GlebasControl from '@spot-spotsat/components/Map/controls/GlebasControl.control';

type ComplianceAmbientalProps = {
  data: OperationMSTypes.ById;
};

function ComplianceAmbiental(props: ComplianceAmbientalProps): JSX.Element {
  const { data }: ComplianceAmbientalProps = props;

  const geometry: GeoJSON.FeatureCollection = toFeatureCollection(
    data.glebes.map((glebe) => glebe.geom)
  );
  const sum_area = data.glebes.reduce(
    (accumulator, currentValue) =>
      accumulator + Number(currentValue.area.replace(',', '.')),
    0
  );
  const summaries = groupBy(
    data?.glebes?.flatMap((glebe) => glebe?.compliance?.summary ?? ''),
    'name'
  );

  //   criação = data de criação da operação

  // **SE** criação **<=** 11/06/2024:
  // - Mantém o cálculo existente (cálculo da bh2);

  // **SE** criação **>** 11/06/2024:
  // - - **SE** os compliances form **APPs** ou **RESERVAS LEGAIS**:
  // - - Porcentagem final = soma / nº de glebas

  // - - **SE** não for:
  // - - Soma todas as porcentagens e já era

  // Alterar exibição:
  // - Separar o "deforestation" por ano e exibir o cálculo pra cada ano.
  // - Colocar os layers no mapa pra cada ano (apenas "deforestation")

  const formatedSummaries = Object.keys(summaries).reduce(
    (accumulator: any, currentValue: any) => {
      if (currentValue === 'Área de desflorestamento') return accumulator;

      const currentObj = summaries[currentValue];

      const sum_ha: number = currentObj.reduce(
        (accumulator, currentValue) =>
          accumulator + Number(currentValue.intersection_area_ha),
        0
      );

      const sum_perc: number = currentObj.reduce(
        (accumulator, currentValue) =>
          accumulator + Number(currentValue.intersection_area_percentage),
        0
      );

      const created_at = new Date(data.created);
      const dividerDate = new Date('06/11/2024');
      let newCurrent = {
        name: '',
        intersection_area_ha: 0,
        intersection_area_percentage: 0,
      };

      if (created_at <= dividerDate) {
        newCurrent = {
          name: currentValue,
          intersection_area_ha: sum_ha || 0,
          intersection_area_percentage: (sum_ha / sum_area) * 100 || 0,
        };
      } else {
        if (
          currentValue === 'Área de reserva legal' ||
          currentValue === 'Área de preservação ambiental'
        ) {
          newCurrent = {
            name: currentValue,
            intersection_area_ha: sum_ha || 0,
            intersection_area_percentage: sum_perc / data.glebes.length || 0,
          };
        } else {
          newCurrent = {
            name: currentValue,
            intersection_area_ha: sum_ha || 0,
            intersection_area_percentage: sum_perc || 0,
          };
        }
      }

      return [...accumulator, newCurrent];
    },
    []
  );

  const groupedByYearDeforestation = (
    summaries['Área de desflorestamento'] || []
  ).reduce((acc, curr) => {
    const year = curr.year;

    if (!year) return acc;

    if (!acc[year]) {
      acc[year] = [curr];
    } else {
      acc[year].push(curr);
    }

    return acc;
  }, {} as Record<string, { color: string; id: number; intersection_area_ha: number; intersection_area_percentage: number; name: string; year: number; type_area: string }[]>);

  const formattedGroupedByYearDeforestation = Object.keys(
    groupedByYearDeforestation
  ).reduce((accumulator: any, currentValue: any) => {
    const currentObj = groupedByYearDeforestation[String(currentValue)];

    const sum_ha: number = currentObj.reduce(
      (accumulator, currentValue) =>
        accumulator + Number(currentValue.intersection_area_ha),
      0
    );

    const sum_perc: number = currentObj.reduce(
      (accumulator, currentValue) =>
        accumulator + Number(currentValue.intersection_area_percentage),
      0
    );

    const created_at = new Date(data.created);
    const dividerDate = new Date('06/11/2024');
    let newCurrent = {
      name: '',
      intersection_area_ha: 0,
      intersection_area_percentage: 0,
    };

    if (created_at <= dividerDate) {
      newCurrent = {
        name: currentValue,
        intersection_area_ha: sum_area || 0,
        intersection_area_percentage: (sum_ha / sum_area) * 100 || 0,
      };
    } else {
      newCurrent = {
        name: `Área de desflorestamento - ${currentValue}`,
        intersection_area_ha: sum_ha || 0,
        intersection_area_percentage: sum_perc || 0,
      };
    }

    return [...accumulator, newCurrent];
  }, []);

  const columns = [
    {
      field: 'name',
      headerName: 'Camada',
      flex: 1,
      minWidth: 300,
      renderCell: (params: any) =>
        params.value === 'undefined' ? '-' : params.value,
    },
    {
      field: 'intersection_area_ha',
      headerName: 'Área (ha)',
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) => `${Number(params.value).toFixed(2)} ha`,
    },
    {
      field: 'intersection_area_percentage',
      headerName: 'Área (%)',
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) => `${Number(params.value).toFixed(2)}%`,
    },
  ] satisfies GridColDef[];

  return (
    <article className={styles.container}>
      <CustomMap
        center={getCentroid(geometry)}
        mapProps={{
          className: styles.map,
          maxBounds: getBbox(geometry),
        }}
        overlays={[<ComplianceOverlay data={data.glebes} />]}
        controls={[<GlebasControl glebes={data.glebes} />]}
      />
      <DataGrid
        initialState={{ pagination: { paginationModel: { pageSize: 20 } } }}
        pageSizeOptions={[20]}
        columns={columns}
        rows={
          !summaries?.undefined
            ? [...formatedSummaries, ...formattedGroupedByYearDeforestation]
            : []
        }
        getRowId={(row) => row.name}
        sx={{ '& .MuiDataGrid-main': { width: 0, minWidth: '100%' } }}
        disableColumnResize
        disableColumnMenu
        autoHeight
      />
    </article>
  );
}

export default ComplianceAmbiental;
