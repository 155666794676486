import React, { useEffect, useState } from 'react';
import PolygonMap from './PolygonMap/PolygonMap.component';
import styles from './Polygons.module.css';
import Panel from './Panel/Panel.component';
import ChartContainer from './ChartContainer/ChartContainer.component';
import { LatLngExpression } from 'leaflet';
import Loading from '@spot-spotsat/components/Loading/Loading';

export type ChartTypes = 'ndvi' | 'weather';

export interface Operation {
  operationId: number;
  centroid: LatLngExpression;
  dataPolygon: any;
  dataChartWeather: any;
  dataChartNdvi: any;
  dataNdviCopernicus: any;
}

interface DateFilter {
  startDate: string;
  endDate: string;
}

function Polygons(): JSX.Element {
  const [geoFilter, setGeoFilter] = useState(null);

  const [selectedCharts, setSelectedCharts] = useState<ChartTypes[]>([]);

  const [showChart, setShowChart] = useState<ChartTypes>();

  const [selectedDataGlebe, setSelectedDataGlebe] = useState<any>({
    dataChartWeather: [],
    dataChartNdvi: [],
    dataNdviCopernicus: [],
    coordinatesGlebe: [],
  });

  const [dataFilter, setDataFilter] = useState<DateFilter>({
    startDate: '',
    endDate: '',
  });

  const [tileLayerNDVI, setTileLayerNDVI] = useState(null);
  const [tileLayerRGB, setTileLayerRGB] = useState(null);

  useEffect(() => {
    if (geoFilter === null) {
      setTileLayerNDVI(null);
      setTileLayerRGB(null);
    }
  }, [geoFilter]);

  useEffect(() => {
    if (selectedCharts.length === 1) {
      setShowChart(selectedCharts[0]);
    }
  }, [selectedCharts]);

  const [selectedGleba, setSelectedGleba] = useState<Operation | null>(null);

  const [selectedOperationPolygons, setOperationPolygons] = useState<any>();

  const getDataOperationPolygons = async (operationId: number) => {
    setOperationPolygons(selectedGleba?.dataPolygon);
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (selectedGleba) {
      getDataOperationPolygons(selectedGleba.operationId);
    }
  }, [selectedGleba]);

  // Referente a tratativa de mensagem de erro do inputDate
  const [searchMessage, setSearchMessage] = useState('');
  const [hasFiltered, setHasFiltered] = useState(false);

  return (
    <div className={styles.page}>
      {isLoading && (
        <div className={styles.loadingForm}>
          <Loading />
        </div>
      )}
      {tileLayerNDVI && (
        <>
          <span className={styles.legends}>
            <p className={styles.p1Legend}>1</p>
            <p className={styles.p0Legend}>0</p>
          </span>
        </>
      )}
      <main className={styles.main}>
        <PolygonMap
          key={JSON.stringify(selectedOperationPolygons)}
          geoFilter={geoFilter}
          setGeoFilter={setGeoFilter}
          dataPolygon={selectedOperationPolygons}
          selectedGleba={selectedGleba}
          setSelectedDataGlebe={setSelectedDataGlebe}
          setDataFilter={setDataFilter}
          tileLayerNDVI={tileLayerNDVI}
          tileLayerRGB={tileLayerRGB}
          setTileLayerNDVI={setTileLayerNDVI}
          setTileLayerRGB={setTileLayerRGB}
          setSearchMessage={setSearchMessage}
          setHasFiltered={setHasFiltered}
        />

        <div className={styles.toolsContainer}>
          <>
            <Panel
              selectedCharts={selectedCharts}
              setSelectedCharts={setSelectedCharts}
              setShowChart={setShowChart}
              geoFilter={geoFilter}
              setGeoFilter={setGeoFilter}
              selectedGleba={selectedGleba}
              setSelectedGleba={setSelectedGleba}
              setIsLoading={setIsLoading}
              selectedDataGlebe={selectedDataGlebe}
              setSelectedDataGlebe={setSelectedDataGlebe}
              selectedOperationPolygons={selectedOperationPolygons}
              dataFilter={dataFilter}
              setDataFilter={setDataFilter}
              setTileLayerNDVI={setTileLayerNDVI}
              setTileLayerRGB={setTileLayerRGB}
              tileLayerNDVI={tileLayerNDVI}
              tileLayerRGB={tileLayerRGB}
              setOperationPolygons={setOperationPolygons}
              searchMessage={searchMessage}
              setSearchMessage={setSearchMessage}
              hasFiltered={hasFiltered}
              setHasFiltered={setHasFiltered}
            />
            {geoFilter && (
              <ChartContainer
                selectedFeature={geoFilter}
                showChart={showChart}
                setShowChart={setShowChart}
                selectedCharts={selectedCharts}
                setSelectedCharts={setSelectedCharts}
                dataWeather={selectedDataGlebe?.dataChartWeather}
                dataNdvi={selectedDataGlebe?.dataChartNdvi}
              />
            )}
          </>
        </div>
      </main>
    </div>
  );
}

export default Polygons;
