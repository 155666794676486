import React from 'react';
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridPaginationModel,
} from '@mui/x-data-grid';
import AlertTag from '../../components/AlertTag/AlertTag.component';
import { Filter } from '../../components/Filter';
import StatusTag from '../../components/StatusTag/Statustag.component';
import Page from '../../layout/components/Page/Page.component';
import styles from './Operations.module.css';
import { useEffect, useRef, useState } from 'react';
import OperationMS from '../../services/ms/OperationMS.service';
import { OperationMSTypes } from '../../types/definitions';
import toCurrency from '../../utils/toCurrency.util';
import toLocaleDate from '../../utils/toLocaleDate.util';
import toNumeric from '../../utils/toNumeric.util';
import {
  NavigateFunction,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import useFilters from '../../hooks/useFilters.hook';

type OperationsData = {
  operations: OperationMSTypes.All['results']['rows'];
  alerts: OperationMSTypes.Alerts;
  cultures: OperationMSTypes.Culture;
  totals: OperationMSTypes.Totals;
};

const initialState: OperationsData = {
  operations: [],
  alerts: [],
  cultures: [],
  totals: {
    total_alerts: 0,
    total_area: '0',
    total_operation: 0,
    total_receivables: 0,
  },
};

function Operations(): JSX.Element {
  const [searchParams] = useSearchParams({
    start_created_at: new Date('2020-01-01').toISOString(),
    end_created_at: new Date().toISOString(),
  });
  const [data, setData] = useState<OperationsData>(initialState);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 20,
  });

  const { filters, clearFilters, addFilter, applyFilters } = useFilters();

  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const navigate: NavigateFunction = useNavigate();

  let isLoading = useRef<boolean>(true);

  useEffect(() => {
    async function fetchData() {
      const { page, pageSize } = paginationModel;
      let offset = page * pageSize;
      let limit = pageSize;

      const newSearchParams: URLSearchParams = new URLSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        offset: offset.toString(),
        limit: limit.toString(),
      });

      const responses = await Promise.allSettled([
        OperationMS.All(newSearchParams),
        OperationMS.Totals(searchParams),
        OperationMS.Alerts(),
        OperationMS.Culture(),
      ]);

      responses.forEach((response, index) => {
        if (response.status !== 'fulfilled') {
          return;
        }
        if (response.value === false) {
          return;
        }

        if (index === 0) {
          setData((prev) => ({
            ...prev,
            operations: (response.value as OperationMSTypes.All).results.rows,
          }));
        } else if (index === 1) {
          setData((prev) => ({
            ...prev,
            totals: response.value as OperationMSTypes.Totals,
          }));
        } else if (index === 2) {
          setData((prev) => ({
            ...prev,
            alerts: response.value as OperationMSTypes.Alerts,
          }));
        } else if (index === 3) {
          setData((prev) => ({
            ...prev,
            cultures: response.value as OperationMSTypes.Culture,
          }));
        }
      });

      isLoading.current = false;
      setInitialLoading(false);
    }

    fetchData();
  }, [searchParams, paginationModel]);

  function onPaginationModelChange(
    model: GridPaginationModel,
    details: GridCallbackDetails
  ): void {
    setPaginationModel(model);
    isLoading.current = true;
  }

  const [stateFilters, setStateFilters] = useState({
    start_created_at: '',
    end_created_at: '',
    culture_id: '',
    alert_type: '',
    document_number: '',
    productor: '',
    is_active: '',
  });

  function handleClearFilters() {
    clearFilters();
    setStateFilters({
      start_created_at: '',
      end_created_at: '',
      culture_id: '',
      alert_type: '',
      document_number: '',
      productor: '',
      is_active: '',
    });
  }

  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  useEffect(() => {
    const hasValues = Object.values(stateFilters).some((value) => value !== '');
    if (hasValues === true && stateFilters.productor !== ' ') {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  }, [stateFilters]);

  function handleApplyFilters() {
    isLoading.current = true;
    applyFilters();
  }

  const getAlertDescription = (type: string) => {
    const alertType = data.alerts.find((alert) => alert.type === type);
    return alertType ? alertType.description : '';
  };

  const getIdCulture = (id: number) => {
    const cultureName = data.cultures.find((culture) => culture.id === id);
    return cultureName ? cultureName.name : '';
  };

  const columns = [
    { field: 'code', headerName: 'Nº', flex: 0, sortable: false },
    {
      field: 'count_alerts',
      headerName: 'Alertas',
      flex: 0,
      renderCell: (params) => <AlertTag value={params.value} />,
    },
    { field: 'owner', headerName: 'Produtor', flex: 1, minWidth: 100 },
    {
      field: 'owner_document',
      headerName: 'CPF / CNPJ / BACEN',
      flex: 1,
      minWidth: 100,
    },
    { field: 'culture_name', headerName: 'Cultura', flex: 1, minWidth: 100 },
    {
      field: 'due',
      headerName: 'Expiração da CPR',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => toLocaleDate(params.value),
    },
    {
      field: 'financed',
      headerName: 'Financeiro',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => toCurrency(params.value),
    },
    {
      field: 'area',
      headerName: 'Area',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => `${params.value} ha`,
    },
    {
      field: 'is_active',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => <StatusTag is_active={params.value} />,
    },
    {
      field: 'actions',
      headerName: 'Acões',
      flex: 0,
      renderCell: (params) => (
        <>
          <a
            className={styles.seeMoreLink}
            onClick={() => navigate(`/operations/${params.row.id}`)}
          >
            Ver mais
          </a>
        </>
      ),
    },
  ] satisfies GridColDef[];

  return (
    <Page
      breadcrumbs={{
        icon: 'Chart',
        breadcrumbs: [{ label: 'Operações', href: '/', active: true }],
      }}
    >
      <Filter.Root>
        <Filter.Group>
          <Filter.Date
            label="Início"
            onChange={(event) => {
              const target = event.target as HTMLDataElement;
              addFilter('start_created_at', target.value);
              setStateFilters({
                ...stateFilters,
                start_created_at: target.value,
              });
            }}
            value={stateFilters['start_created_at'] || ''}
          />
          <Filter.Date
            label="Fim"
            onChange={(event) => {
              const target = event.target as HTMLDataElement;
              addFilter('end_created_at', target.value);
              setStateFilters({
                ...stateFilters,
                end_created_at: target.value,
              });
            }}
            value={stateFilters['end_created_at'] || ''}
          />
          <Filter.Selector
            loading={isLoading.current}
            label="Cultura"
            itemsType="object"
            itemLabelKey="name"
            itemValueKey="id"
            items={data.cultures}
            onClick={(event) => {
              const target = event.target as HTMLSelectElement;
              addFilter('culture_id', target.value);
              setStateFilters({ ...stateFilters, culture_id: target.value });
            }}
            value={getIdCulture(Number(filters['culture_id'])) || 'Todos'}
          />
          <Filter.Selector
            label="Alerta"
            itemsType="object"
            itemLabelKey="description"
            itemValueKey="type"
            items={data.alerts}
            onClick={(event) => {
              const target = event.target as HTMLInputElement;
              addFilter('alert_type', target.value);
              setStateFilters({ ...stateFilters, alert_type: target.value });
            }}
            value={getAlertDescription(filters['alert_type']) || 'Todos'}
          />
          <Filter.Text
            label="CPF / CNPJ / BACEN"
            type="text"
            onChange={(event) => {
              const target = event.target as HTMLInputElement;
              const sanitizedValue = target.value
                .replace(/\D/g, '')
                .slice(0, 50);
              addFilter('document_number', sanitizedValue);
              setStateFilters({
                ...stateFilters,
                document_number: sanitizedValue,
              });
            }}
            value={stateFilters['document_number'] || ''}
          />
          <Filter.Text
            label="Produtor"
            type="text"
            onChange={(event) => {
              const target = event.target as HTMLInputElement;
              const sanitizedValue = target.value
                .replace(/[^a-zA-ZÀ-ÿ\s]/g, '')
                .replace(/\s{2,}/g, ' ');
              addFilter('productor', sanitizedValue.trim());
              setStateFilters({ ...stateFilters, productor: sanitizedValue });
            }}
            value={stateFilters['productor'] || ''}
          />
          <Filter.Selector
            loading={isLoading.current}
            label="Status"
            itemsType="string"
            items={['Finalizado', 'Vigente']}
            onClick={(event) => {
              const target = event.target as HTMLSelectElement;
              const newValue = target.value === 'Vigente' ? 'true' : 'false';
              addFilter('is_active', newValue);
              setStateFilters({ ...stateFilters, is_active: newValue });
            }}
            value={
              filters['is_active'] === 'true'
                ? 'Vigente'
                : filters['is_active'] === 'false'
                ? 'Finalizado'
                : 'Todos'
            }
          />
        </Filter.Group>
        <Filter.Group type="flex">
          <Filter.Button
            label="Limpar filtros"
            variant="outline-secondary"
            iconName="Close"
            onClick={handleClearFilters}
          />
          <Filter.Button
            label="Filtrar"
            variant="filled-secondary"
            iconName="Filter"
            onClick={handleApplyFilters}
            className={`${!isButtonEnabled && styles.disabledButton}`}
          />
        </Filter.Group>
      </Filter.Root>
      <section className={styles.infos}>
        <div className={styles.info}>
          <p className={styles.title}>Total de operações</p>
          <p className={styles.content}>
            {initialLoading ? '-' : toNumeric(data.totals.total_operation)}
          </p>
        </div>
        <div className={styles.info}>
          <p className={styles.title}>Total de alertas</p>
          <p className={styles.content}>
            {initialLoading ? '-' : toNumeric(data.totals.total_alerts)}
          </p>
        </div>
        <div className={styles.info}>
          <p className={styles.title}>Total de hectares</p>
          <p className={styles.content}>
            {initialLoading ? '-' : `${data.totals.total_area} ha`}
          </p>
        </div>
        <div className={styles.info}>
          <p className={styles.title}>Total de recebíveis</p>
          <p className={styles.content}>
            {initialLoading ? '-' : toCurrency(data.totals.total_receivables)}
          </p>
        </div>
      </section>
      <section className={styles.table}>
        <DataGrid
          loading={isLoading.current}
          initialState={{ pagination: { paginationModel: { pageSize: 20 } } }}
          paginationMode="server"
          onPaginationModelChange={onPaginationModelChange}
          pageSizeOptions={[20]}
          columns={columns}
          rowCount={data.totals.total_operation}
          rows={data.operations}
          sx={{ '& .MuiDataGrid-main': { width: 0, minWidth: '100%' } }}
          disableColumnResize
          disableColumnMenu
          autoHeight
        />
      </section>
    </Page>
  );
}

export default Operations;
