import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Page from '../../layout/components/Page/Page.component';
import { useEffect, useState } from 'react';
import OperationTabs from './components/OperationTabs';
import styles from './Operation.module.css';
import Tag from '../../components/Tag/Tag.component';
import cls from '../../utils/cls.util';
import Icon from '../../components/Icon/Icon.component';
import OperationMS from '../../services/ms/OperationMS.service';
import { OperationMSTypes } from '../../types/definitions';
import toCurrency from '../../utils/toCurrency.util';
import toLocaleDate from '../../utils/toLocaleDate.util';
import Loading from '../../components/Loading/Loading';

function Operation(): JSX.Element {
  const { id } = useParams();
  const navigate = useNavigate();

  const [tab, setTab] = useState<'Compliance Ambiental' | 'Laudo Agricola'>(
    'Compliance Ambiental'
  );
  const [data, setData] = useState<OperationMSTypes.ById | undefined>(
    undefined
  );
  const [isGeneratingReport, setIsGeneratingReport] = useState<boolean>(false);
  const [isReprocessing, setIsReprocessing] = useState<boolean>(false);

  useEffect(() => {
    async function fetchData() {
      if (!id) return;

      const responses = await Promise.allSettled([OperationMS.ById(id)]);

      responses.forEach((response, index) => {
        if (response.status !== 'fulfilled') {
          return;
        }
        if (response.value === false) {
          return;
        }

        if (index === 0) {
          setData(response.value as OperationMSTypes.ById);
        }
      });
    }

    fetchData();
  }, [id]);

  if (!id) {
    navigate(-1);
    return <></>;
  }

  if (!data) {
    return (
      <div className={styles.divLoading}>
        <Loading />
      </div>
    );
  }

  async function downloadOperation() {
    if (!data) return;
    if (!id) return;

    setIsGeneratingReport(true);

    try {
      await OperationMS.Report(id);

      setTimeout(() => {
        setIsGeneratingReport(false);
      }, 60000);
    } catch (error) {
      setIsGeneratingReport(false);
      return;
    }
  }

  async function reprocessOperation() {
    if (!data) return;
    if (!id) return;

    const response: any = await OperationMS.Reprocess(id);

    if (!response) {
      alert('Falha ao reprocessar');
      return;
    }

    setIsReprocessing(true);
  }

  return (
    <Page
      breadcrumbs={{
        icon: 'Chart',
        breadcrumbs: [
          { label: 'Operações', href: `/operation`, active: true },
          { label: id, href: `/operation/${id}`, active: true },
        ],
      }}
    >
      <section className={styles.actions}>
        <button onClick={() => navigate(-1)}>
          <Icon name="ArrowLeft" />
          Voltar
        </button>
        <button onClick={downloadOperation} disabled={isGeneratingReport}>
          {!isGeneratingReport && (
            <>
              <Icon name="Email" /> Receber relatório por email
            </>
          )}
          {isGeneratingReport && <>Relatório enviado!</>}
        </button>
        <button onClick={reprocessOperation} disabled={isReprocessing}>
          {!isReprocessing && (
            <>
              <Icon name="RotateCW" /> Reprocessar
            </>
          )}
          {isReprocessing && <>Reprocessando...</>}
        </button>
      </section>
      <section className={styles.metadata}>
        {data.is_active && <Tag label="Monitoramento em andamento" />}
        {!data.is_active && <Tag label="Monitoramento finalizado" />}
        <Tag label={`Criado em ${toLocaleDate(data.created)}`} />
        <Tag label={`Atualizado em ${toLocaleDate(data.modified, true)}`} />
      </section>
      <section className={styles.alerts}>
        <h3>
          Alertas <small>({data.alerts.length})</small>
        </h3>
        <div>
          {data.alerts.map((alert, index) => (
            <Tag key={index} label={alert.description} />
          ))}
        </div>
      </section>
      <section className={styles.details}>
        <h3>Operação</h3>
        <div className={styles.info}>
          <span className={styles.data}>
            <small>Número</small>
            <p>{data.code}</p>
          </span>
          <span className={styles.data}>
            <small>Ref. BACEN</small>
            <p>{data.bacen_code}</p>
          </span>
          <span className={styles.data}>
            <small>Financiador</small>
            <p>{data.company.name}</p>
          </span>
          <span className={styles.data}>
            <small>CNPJ Cooperativa</small>
            <p>{data.company.document_number}</p>
          </span>
          <span className={styles.data}>
            <small>Valor financiado</small>
            <p>{toCurrency(data.total_financed)}</p>
          </span>
          <span className={styles.data}>
            <small>Área financiada</small>
            <p>
              {data.financed_area} {data.area_type}
            </p>
          </span>
          <span className={styles.data}>
            <small>Cultura financiada</small>
            <p>{data.culture_name}</p>
          </span>
          <span className={styles.data}>
            <small>Localização</small>
            <p>
              {data.address.city} - {data.address.state}
            </p>
          </span>
          <span className={styles.data}>
            <small>Latitude / Longitude</small>
            <p>
              {data.coordinates.lat} / {data.coordinates.lng}
            </p>
          </span>
        </div>
      </section>
      <section className={styles.details}>
        <h3>Produtor</h3>
        <div className={styles.info}>
          <span className={styles.data}>
            <small>Nome</small>
            <p>{data.owner}</p>
          </span>
          <span className={styles.data}>
            <small>CPF</small>
            <p>{data.owner_document}</p>
          </span>
        </div>
      </section>
      <section className={styles.tabs}>
        <div>
          <button
            onClick={() => setTab('Compliance Ambiental')}
            className={cls(
              tab === 'Compliance Ambiental',
              styles.tabButton,
              styles.selected
            )}
          >
            Compliance ambiental
          </button>
          <button
            onClick={() => setTab('Laudo Agricola')}
            className={cls(
              tab === 'Laudo Agricola',
              styles.tabButton,
              styles.selected
            )}
          >
            Laudo agrícola
          </button>
        </div>
        {tab === 'Compliance Ambiental' && (
          <OperationTabs.ComplianceAmbiental data={data} />
        )}
        {tab === 'Laudo Agricola' && (
          <OperationTabs.LaudoAgricola data={data} />
        )}
      </section>
    </Page>
  );
}

export default Operation;
